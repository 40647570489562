import React from 'react';
import { fromEvent, from, of, EMPTY, merge, interval, timer, combineLatest } from 'rxjs';
import { filter, map, mergeMap, scan, withLatestFrom, groupBy, pairwise, catchError, takeWhile, concatMap,
  startWith, tap, delay, switchMap, distinctUntilChanged, take, share, debounceTime, takeUntil, publishReplay, shareReplay} from 'rxjs/operators';
import Dispatch from '@Corim/architecture-frontend/build/architecture/Dispatch';
import { BaseComponent, Textbox } from '@Corim/architecture-frontend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollingIcons from './ScrollingIcons'
import { faStore, faBars } from '@fortawesome/pro-regular-svg-icons'
import { faAngleDown, faIceCream, faPizzaSlice } from '@fortawesome/free-solid-svg-icons'
import get_sauced from '../media/get_sauced.png'

const flicker$ = timer(0, 2500)
  .pipe(
    mergeMap(e => {
      const randomTime1 = Math.random() * 1000
      const randomTime2 = Math.random() * 1000
      return merge(
        timer(randomTime1)
          .pipe(
            map(e => true)
          ),
        timer(randomTime1+50)
          .pipe(
            map(e => false)
          ),
        timer(randomTime2)
          .pipe(
            map(e => true)
          ),
        timer(randomTime2+50)
          .pipe(
            map(e => false)
          ),
        )
      }
    ),
    startWith(false)
  )

const Component = (props) => {
  const flicker = props && props.flicker
  const headerHeight = 70
  const innerHeight = props.ui && props.ui.inner && props.ui.inner.height
  const showNeon = flicker ? ' o-0 ' : ' o-100 '
  return (
    <div className='w-100 mw6 sticky flex items-center justify-center' style={{top:headerHeight, height: (innerHeight - headerHeight) * 1.5, zIndex:1}}>
      <div className='w-100 relative' style={{top: (innerHeight) * -.3 }}>
        <img className={'w-100 relative' + showNeon } src={get_sauced} style={{top:40}}/>
      </div>
      {/* <div className='pt5 mt5 f2 fw7 pink'>Order Online!</div> */}
    </div>
  )
}

const Store$ = combineLatest(Dispatch.getState(), flicker$)
  .pipe(
    map(([store, flicker]) => ({
      ...store,
      flicker,
    }))
  )
    
export default (props) => <BaseComponent Component={Component} store={Store$} {...props}/>