import { Dispatch, History } from '@Corim/architecture-frontend';
import { startWith, map, filter, delay, switchMap, tap, takeUntil, skip, share, distinctUntilChanged, publishReplay, refCount, debounceTime, buffer, take } from 'rxjs/operators'
import { timer, of, merge, fromEvent, interval, Observable, combineLatest } from "rxjs";
import queryString from 'query-string'
import _ from 'lodash'


  // Url Watcher
Observable.create(observer => {
    History.listen((location, action) => observer.next({ location, action }))
  })
  .pipe(
    filter(e => e.action === 'POP'),
    startWith({ location: History.location }),
  )
  .subscribe(e => Dispatch.nextAction('history.pop', e))

const debouncer$ = Dispatch.getAction('navigate')
  .pipe(
    debounceTime(100)
  )

// Don't start listening until everything is loaded
of(1).pipe(delay(2))
.subscribe(f => {
  // Url Pusher
  Dispatch.getAction('navigate')
    .pipe(
      // Buffers objects so that mulitple requests do not trigger multiple history pushes
      buffer(debouncer$),
    )
      .subscribe(e => {
        const parsed = queryString.parse(History.location.search)
        const minimizedArray = _.uniqBy(_.reverse(e), item => Object.keys(item)[0])
        const queryObject = minimizedArray.reduce((acc, next) => {
          let search = acc
          const key = Object.keys(next)[0]
          if (parsed[key] !== next[key]){

            // Allows for undefined to be removed from search bar
            if (_.isNil(next[key])) {
              const tempSearch = {...acc }
              delete tempSearch[key]
              search = tempSearch
            }
            else {
              search = {...acc, ...next }
            }
          }
          return search
        }, queryString.parse(History.location.search))
        //FIXME isEqual may be a problem if ordered differently????
        if (Object.keys(queryObject).length !== 0 && !_.isEqual(queryObject, parsed)) {
          History.push({
            pathname: '/',
            search: queryString.stringify(queryObject) 
            })
        }
      })
})


      Dispatch.getState('view')
        .subscribe(e => Dispatch.nextAction('navigate', { view: e }))

      // Dispatch.getStateUndefined('selections.zip')
      //   .subscribe(e => Dispatch.nextAction('navigate', { location: e }))


      // Dispatch.getStateUndefined('selections.restaurant')
      //   .subscribe(e => Dispatch.nextAction('navigate', { restaurant: e }))


    // Dispatch.getState('view')
    //   .subscribe(e => Dispatch.nextAction('navigate', { view: e }))

    // // Dispatch.getStateUndefined('selections.zip')
    // //   .subscribe(e => Dispatch.nextAction('navigate', { location: e }))

    // Dispatch.getStateUndefined('selections.dropspot')
    //   .subscribe(e => Dispatch.nextAction('navigate', { dropspot: e }))

    // Dispatch.getStateUndefined('selections.restaurant')
    //   .subscribe(e => Dispatch.nextAction('navigate', { restaurant: e }))
    
    combineLatest( Dispatch.getAction('history.pop'), of(1).pipe(take(1),delay(100)))
      // Makes sure that this loads after session
      .pipe(delay(1))
      .subscribe(([e, session]) => {
        const searchString = History.location.search
        const search = queryString.parse(searchString)

        // Order of loading matters
        // if (session.placeId) {
        //   Dispatch.nextAction('place', 'select', { placeId: session.placeId, placeDescription: session.placeDescription } )
        // }
        // if (search.restaurant) {
        //   Dispatch.nextAction('restaurant', 'select', search.restaurant )
        // }

        const view = search.view ? search.view : 'home'
        Dispatch.nextAction('view.' + view )
      })