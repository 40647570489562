import React from 'react';
import { BaseComponent, Textbox } from '@Corim/architecture-frontend';
import { fromEvent, from, of, EMPTY, merge, interval, timer } from 'rxjs';
import { filter, map, mergeMap, scan, withLatestFrom, groupBy, pairwise, catchError, takeWhile, concatMap,
  startWith, tap, delay, combineLatest, switchMap, distinctUntilChanged, take, share, debounceTime, takeUntil, publishReplay, shareReplay} from 'rxjs/operators';
import Dispatch from '@Corim/architecture-frontend/build/architecture/Dispatch';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faIceCream, faPizzaSlice } from '@fortawesome/free-solid-svg-icons'

const scrollDistance$ = fromEvent(document, 'scroll')
  .pipe(
    map(e => ({ scrolledX: e.target.documentElement.scrollTop + e.target.body.scrollTop })),
  )

const Reverse = (props) => {
  const scrolledX = props.scrolledX*2
  const innerHeight = props.ui && props.ui.inner && props.ui.inner.height
  const baseStart = 2000 + innerHeight*2
  if (scrolledX < window.innerHeight*1.2) {
    return (
      <div className='w-100 fixed' style={{zIndex:99}}>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .1, top: scrolledX - 50}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .3, top: scrolledX * .4}}><FontAwesomeIcon icon={faPizzaSlice} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .5, top: scrolledX * .9}}><FontAwesomeIcon icon={faPizzaSlice} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .7, top: scrolledX * .7}}><FontAwesomeIcon icon={faPizzaSlice} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .8, top: scrolledX * 1.5 - 100}}><FontAwesomeIcon icon={faPizzaSlice} /></div>
      </div>
    )
  }
  else if (scrolledX > 500 + innerHeight) {
    return (
      <div className='w-100 fixed' style={{zIndex:99}}>
        <div className='fixed f3 white' style={{left: window.innerWidth * .1, top: baseStart - scrolledX - 50}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .3, top: baseStart - (scrolledX * 1.13)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .5, top: baseStart - (scrolledX * 1.23)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .7, top: baseStart - scrolledX * 1.11}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .8, top: baseStart - scrolledX * 1.19 - 100}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .1, top: baseStart + 1000 - scrolledX - 50}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .3, top: baseStart + 1000 - (scrolledX * 1.13)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .5, top: baseStart + 1000 - (scrolledX * 1.23)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .7, top: baseStart + 1000 - scrolledX * 1.11}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .8, top: baseStart + 1000 - scrolledX * 1.19 - 100}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .1, top: baseStart + 2000 - scrolledX - 50}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .3, top: baseStart + 2000 - (scrolledX * 1.13)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .5, top: baseStart + 2000 - (scrolledX * 1.23)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .7, top: baseStart + 2000 - scrolledX * 1.11}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .8, top: baseStart + 2000 - scrolledX * 1.19 - 100}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .1, top: baseStart + 3000 - scrolledX - 50}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .3, top: baseStart + 3000 - (scrolledX * 1.13)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .5, top: baseStart + 3000 - (scrolledX * 1.23)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .7, top: baseStart + 3000 - scrolledX * 1.11}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .8, top: baseStart + 3000 - scrolledX * 1.19 - 100}}><FontAwesomeIcon icon={faIceCream} /></div>
        
        <div className='fixed f3 pink' style={{left: window.innerWidth * .1, top: baseStart + 4000 - scrolledX - 50}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .3, top: baseStart + 4000 - (scrolledX * 1.13)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .5, top: baseStart + 4000 - (scrolledX * 1.23)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .7, top: baseStart + 4000 - scrolledX * 1.11}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .8, top: baseStart + 4000 - scrolledX * 1.19 - 100}}><FontAwesomeIcon icon={faIceCream} /></div>

        <div className='fixed f3 pink' style={{left: window.innerWidth * .1, top: baseStart + 5000 - scrolledX - 50}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .3, top: baseStart + 5000 - (scrolledX * 1.13)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .5, top: baseStart + 5000 - (scrolledX * 1.23)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .7, top: baseStart + 5000 - scrolledX * 1.11}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .8, top: baseStart + 5000 - scrolledX * 1.19 - 100}}><FontAwesomeIcon icon={faIceCream} /></div>

        <div className='fixed f3 pink' style={{left: window.innerWidth * .1, top: baseStart + 6000 - scrolledX - 50}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .3, top: baseStart + 6000 - (scrolledX * 1.13)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 white' style={{left: window.innerWidth * .5, top: baseStart + 6000 - (scrolledX * 1.23)}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .7, top: baseStart + 6000 - scrolledX * 1.11}}><FontAwesomeIcon icon={faIceCream} /></div>
        <div className='fixed f3 pink' style={{left: window.innerWidth * .8, top: baseStart + 6000 - scrolledX * 1.19 - 100}}><FontAwesomeIcon icon={faIceCream} /></div>
      </div>
    )
  }
  else
    return null
}

export default (props) => <BaseComponent Component={Reverse} store={scrollDistance$} {...props}/>