import React from 'react';
import sauced_strawberry_left from './media/sauced_strawberry_left.png'
import sauced_strawberry_right from './media/sauced_strawberry_right.png'
import sauced_vanilla_left from './media/sauced_vanilla_left.png'
import sauced_vanilla_right from './media/sauced_vanilla_right.png'
import loading from './media/loading.svg'
import sauced_pizza_half from './media/sauced_pizza_half.png'
import sauced_strawberry_gelato from './media/sauced_strawberry_gelato.png'
import sauced_vanilla_gelato from './media/sauced_vanilla_gelato.png'
import sauced_blueberry_gelato from './media/sauced_blueberry_gelato.png'
import { Textbox } from '@Corim/architecture-frontend';
import { fromEvent, of, merge, timer } from 'rxjs';
import { filter, map,
  startWith,  distinctUntilChanged, take, share, delay } from 'rxjs/operators';
import { Dispatch, Modal, SubmitButton } from '@Corim/architecture-frontend';
import _ from 'lodash';
import Intro from './components/Intro'
import GetSauced from './components/GetSauced'
import Header from './components/Header'
import saucedPizza from './media/sauced_pizza.jpg'
import application from './media/Standard Application.pdf'
import MenuView from './components/TVMenu'

import { ShoppingIcon, Store } from '@Corim/squareshop'
// Modal Views
import { Payment, Checkout, ThankYou, ItemDetail, Fullfillment, Admin, CustomerDetails, OrderManager, OrderManagerStateModal }  from '@Corim/squareshop'
import './settings'

const scrollDistance$ = fromEvent(document, 'scroll')
  .pipe(
    map(e => ({ scrolledX: e.target.documentElement.scrollTop + e.target.body.scrollTop })),
    share()
  )

scrollDistance$
    .pipe(
      filter(e => e.scrolledX > 2),
      take(1)
    )
    .subscribe(e => Dispatch.nextState('ui.color', 'black'))

const size$ = merge(fromEvent(window, 'resize')
      .pipe(
        map(e => ({ height: e.target.innerHeight, width: e.target.innerWidth, outerHeight: e.target.outerHeight })),
      ),
      of({ height: window.innerHeight, width: window.innerWidth, outerHeight: window.outerHeight })
    )
    .pipe(
      distinctUntilChanged((prev, next) => prev.outerHeight === next.outerHeight),
    )
    .subscribe(e => Dispatch.nextState('ui.inner', e))

timer(60000)
    .pipe(
      take(1),
      map(e => 'black'),
      startWith('white')
    )
    .subscribe(e => Dispatch.nextState('ui.color', e))

// const size$ = fromEvent(window, 'resize')
//   .pipe(
//     map(e => ({ height: e.target.innerHeight, width: e.target.innerWidth, ratio: e.target.devicePixelRatio})),
//     startWith({ height: window.innerHeight, width: window.innerWidth, ratio: window.devicePixelRatio})
//   )
//   .subscribe(e => Dispatch.mergeState('ui', { windowSize: e }))

// const displayIntroExplanation$ = scrollDistance$
//   .pipe(
//     map(e => (window.innerHeight - (e.target.documentElement.scrollTop + e.target.body.scrollTop) > 0 )),
//     startWith( true)
//   )



// const Intro = (props) => {
//   const scrolledX = props.scrolledX
//   const percentScreenHeight = window.outerHeight / scrolledX
// }
//Fade in colors???

const primaryColor = 'gold'
const secondaryColor = 'white'

const Homepage = (props) => {
  const bgColor = props.ui && props.ui.color
  const flicker = props.ui && props.ui.flicker
  const headerHeight = 70
  const innerHeight = props.ui && props.ui.inner && props.ui.inner.height
  const maxWidth = innerHeight / 1.8 > 550 ? 550 : innerHeight / 1.8
  const loaded = props.ui && props.ui.loaded
  const loadedOpacity = props.ui && props.ui.loaded ? ' o-0 ' : ' o-100 '
  //const percentScreenHeight = window.outerHeight / scrollDistance$
  //const primaryColor = flicker ? ' dark-gray ' : ' pink '
  //const borderColor = flicker ? ' b--dark-gray ' : ' b--pink '
  return (
    <div className={ "App avenir bg-fader bg-" + bgColor}>

      <div className={'absolute vh-100 w-100 flex justify-center items-center ' + loadedOpacity }>
        <div className='w3'>
          <img src={loading} />
        </div>
      </div>
      <Intro {...props}/>
      {loaded ?
      <>
        <div id='about' className='pb4 pb3-ns bg-pink white flex items-center flex-column' >
          <div className='w-100 mw6 sticky' style={{top:55}}><img className='w-100' src={sauced_pizza_half}/></div>
          <div className='h2'></div>
          <div className='mw6 ph3 pt4 pt3-ns f1 fw3 tc dark-red'><span className='athelas fw7'>Sauced</span> Pizza</div>
          <div className='mw6 pb5 ph4 pt3 pt4-ns f4 fw4 tc'>We have the special sauce. Actually, it's just tomato sauce. But it's really good tomato sauce. The best tomato sauce!
          <br/><br/>
          We're a locally sourced, seasonally inspired, 100% wood fired Neapolitan style pizzeria.
          <br/><br/>
          That's quite the mouthful, but we have more for your mouth: proscuitto and basil and pepperoni and mushrooms...
          <br/><br/>
          Our crust is 100% natural and consists of only four simple ingredients: flour, water, salt & fresh yeast.
          <br/><br/>
          <span className='fw7'>We think you'll want our pizza.</span>
          </div>
          <div className='h2'></div>
        </div>
        <div className=' bg-black white flex items-center flex-column'>
          {/* <div className='h4'></div> */}
          <GetSauced {...props}/>

          <div className='w-100 pb2 bg-black' style={{zIndex:4}}>
            <div className='w-100 bw1 bb b--pink' style={{zIndex:4}}></div>
            <div className='w-100 pb1 bw2 bb b--pink' style={{zIndex:4}}></div>
            <div className='w-100 pt1 pb1 mb1 bw3 bb b--pink' style={{zIndex:4}}></div>
          </div>

          <div className='w-100 pa3 bg-pink f4 fw7 white sticky tc' style={{top:headerHeight, zIndex:3}} onClick={e => Dispatch.nextAction('view.store')}>Order Online </div>
          {/* <div className='h6'></div> */}
          {/* <div className='w-100 pa3 bg-pink f3 fw7 white'>Order Online</div>
          <div className='w-100 pa3 bg-white f3 fw7 pink'>Order Online</div> */}
          <div className='w-100 bg-black' style={{zIndex:2}}>
            <div className='w-100 pt1 pb1 mt1 bw3 bb b--pink' style={{zIndex:2}}></div>
            <div className='w-100 pt1 pb1 bw2 bb b--pink' style={{zIndex:2}}></div>
            <div className='w-100 pt1 bw1 bb b--pink' style={{zIndex:2}}></div>
          </div>

          <div className='w-100 bg-black' style={{zIndex:2, height: innerHeight/6}}></div>
          {/* <div className='pt4 bg-black pink flex items-center flex-column' style={{zIndex: 900}} >
            <img className='mw5 ph3 relative w-50' src={sauced_gelato} />
            <div className='mw6 ph3 pt3 f2 fw7 tc'>Melty Gelato</div>
            <div className='mw6 pt4 f4 fw4 tc'>Drip drip bb. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. dasdfsadfsdafsd. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. dasdfsadfsdafsd</div>
          </div> */}
        {/* </div> */}
        {/* <div className='w-100 pa3 bg-white f3 fw7 pink sticky' style={{top:headerHeight}}> Order Online</div>
        <div className='w-100 pa3 bg-pink f3 fw7 white'>Order Online</div>
        <div className='w-100 pa3 bg-white f3 fw7 pink'>Order Online</div> */}
        <div className='w-100 pt5 pb5 bg-black pink flex items-center flex-column' style={{zIndex:2}}>
          <div className='di w-100 flex justify-between' >
            <div className='relative di mw3 w-10 bg-black' style={{top:70, left: 0}}>
              <img className='w-100' src={sauced_strawberry_right} />
            </div>
            <div className='relative di mw3 w-10 bg-black' style={{top:0, left: 0}}>
              <img className='w-100' src={sauced_strawberry_left} />
            </div>
            {/* <img className='relative inline mw3 w-10 bg-black' style={{top:-40, left: 0}} src={sauced_strawberry_right} /> */}
            {/* <img className='relative inline mw3 w-10 bg-black' style={{top:30, left: 0}} src={sauced_strawberry_left} /> */}
          </div>
          <img className='ph4 pt4 pt5-ns relative w-60' src={sauced_strawberry_gelato} style={{maxWidth:300}}/>
          <div className='mw6 ph4 pt4 f2 fw7 tc white'>Organic Strawberry
            <div className='f6 fw7 pt1 pink'>milk, cream, strawberries, eggs, sugar, & lemon juice </div> 
          </div>
          {/* <div className='mw6 ph4 pt4 f4 fw4 tc white'>Oh this is the one! Saucy & pink! 7 ingredients in this one: sensual strawberries, milk, cream, eggs, sugar & lemon juice. 
          <br/><br/> Wait, thats only 6! We've outdone ourselves!!!  </div> */}
        </div>
        <div className='w-100 pt1 bg-black pink flex items-center flex-column' style={{zIndex:2}}>
          <div className='di w-100 flex justify-between' >
            <div className='relative di mw4 w-30 bg-black' style={{top:50, left: 0}}>
              <img className='w-100' src={sauced_vanilla_right} />
            </div>
            <div className='relative di mw4 w-20 bg-black' style={{top:110, left: 0}}>
              <img className='w-100' src={sauced_vanilla_left} />
            </div>
            {/* <img className='relative mw4 w-25 bg-black' style={{top:-40, left: 0}} src={sauced_vanilla_right} /> */}
            {/* <img className='relative mw4 w-20 bg-black' style={{top:30, left: 0}} src={sauced_vanilla_left} /> */}
          </div>
          {/* <FlyingFruit /> */}
          <img className='ph4 pt5 relative w-60' src={sauced_vanilla_gelato} style={{maxWidth:300}}/>
          
          <div className='mw6 ph4 pt4 f2 fw7 white'>
            {/* <span className='fw7'>
              M
              <span className='white'>a</span>
              d
              <span className='white'>a</span>
              g
              <span className='white'>s</span>
              c
              <span className='white'>a</span>
              r
            </span>
            <span className='white'> V</span>
            a
            <span className='white'>n</span>
            i
            <span className='white'>l</span>
            l
            <span className='white'>a</span> */}
            Organic Vanilla
            <div className='f6 fw7 pt1 pink'>milk, cream, eggs, sugar, & vanilla beans </div> 
            </div>
            
        </div>
        <div className='w-100 pt5 bg-black pink flex items-center flex-column' style={{zIndex:2}}>
          <div className='di w-100 flex justify-between' >
            <div className='relative di mw3 w-10 bg-black' style={{top:70, left: 0}}>
              <img className='w-100' src={sauced_strawberry_right} />
            </div>
            <div className='relative di mw3 w-10 bg-black' style={{top:0, left: 0}}>
              <img className='w-100' src={sauced_strawberry_left} />
            </div>
            {/* <img className='relative mw3 w-10 bg-black' style={{top:30, left: 0}} src={sauced_strawberry_right} />
            <img className='relative mw3 w-10 bg-black' style={{top:-40, left: 0}} src={sauced_strawberry_left} /> */}
          </div>
          <img className=' ph4 pt4 pt5-ns relative w-60' src={sauced_blueberry_gelato} style={{maxWidth:300}} />
          <div className='mw6 ph4 pt4 f2 fw7 tc white'>
            {/* B 
            <span className='light-blue'>l</span>
            u
            <span className='light-blue'>e</span>
            b
            <span className='light-blue'>e</span>
            r
            <span className='light-blue'>r</span>
            y 
            <span className='light-blue'> D</span>
            r
            <span className='light-blue'>e</span>
            a
            <span className='light-blue'>m</span> */}
            Organic Blueberry
            <div className='f6 fw7 pt1 pink'>milk, cream, blueberries, eggs, sugar, & lemon juice </div> 
            </div>
          {/* <div className='mw6 ph4 pt4 f4 fw4 tc white'>We prefer that blueberries were actually pink, but they arent. So...we're sorry.</div> */}
        </div>
        <div className='h4'></div>
        </div>
        {/* <div className='pt5 bg-black pink flex items-center flex-column' style={{zIndex: 900}} >
          <img className='mw6 relative w-60' src={sauced_folded_pizza} />
          <div className='w-100 mw6 ph3 pt4 f2 fw7 tc tl-ns'> <span className='white'>Opt 2:</span> Just Fold It!</div>
          <div className='w-100 mw6 ph3 pt4 f4 fw4 tc tl-ns'>Haven't you ever eaten a pizza? GET IN THERE. FOLD THAT THING. Good. Now you're doing it. Drip. Drip. The melty galato will sooth the inner crevices of your tastebuds. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. dasdfsadfsdafsd. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. Get it now! All over. dasdfsadfsdafsd</div>
        </div> */}
          {/* <div className='w-100 pt1 bw1 bb b--pink' style={{zIndex:4}}></div>
          <div className='w-100 pb1 bw2 bb b--pink' style={{zIndex:4}}></div>
          <div className='w-100 pt1 pb1 mb1 bw3 bb b--pink' style={{zIndex:4}}></div> */}
        <div>
          <div id='hours' className='w-100 pa3 bg-white f3 fw7 tc sticky bb b--light-gray' style={{top:headerHeight, zIndex:3}}> <span className='w-100 link pink' >Or Come Say Hi!</span> </div>

          {/* <div className='w-100 bg-black' style={{zIndex:2}}>
            <div className='w-100 pt1 pb1 mt1 bw3 bb b--pink' style={{zIndex:2}}></div>
            <div className='w-100 pt1 pb1 bw2 bb b--pink' style={{zIndex:2}}></div>
            <div className='w-100 pt1 bw1 bb b--pink' style={{zIndex:2}}></div>
          </div> */}

          <div className='w-100 pt3 pb5 ph4 pink f4 flex flex-column items-center tc'>
            <div className='w-100 mw6'>
              {/* <div className='pt4 f1 fw6 white'>Hours</div>  */}
              <div className='pt5 f2 fw9'>Hours & Directions </div>
              <div className='pt4 fw7'><span className='fw6 white'>Tues-Sun: </span> 4pm to 8pm</div>
              {/* <div className='pt4 fw7'><span className='fw6 white'>Wed: </span> 3pm to 8pm</div>
              <div className='pt1 fw7'><span className='fw6 white'>Thurs: </span> 3pm to 8pm</div>
              <div className='pt1 fw7'><span className='fw6 white'>Fri-Sun: </span> Noon to 8pm</div> */}
              {/* <div className='pt1 fw7'><span className='fw6 white'>Sun: </span> Noon to 8pm</div> */}
              {/* <div className=''><span className='fw6 white'>Sun: </span> 11am to 11pm</div>
              <div className='pb5'><span className='fw6 white'>Mon: </span> chillin at home (without Netflix)</div> */}
            </div>
            <div className='pt4 flex justify-center'>
              <div className='' dangerouslySetInnerHTML={{__html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11713.325445837312!2d-88.41246788427827!3d42.781344300218386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcee7b724fd6cb106!2sSauced!5e0!3m2!1sen!2sus!4v1583791241669!5m2!1sen!2sus"  height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>' }}/>
            </div>
            <div className='pt3 pb4 fw7'>2886 Main St. East Troy, WI 53120</div>
          </div>
        </div>

        <div id='jobs' className='pa3 ph4 pb5 bg-white flex justify-center tc tl-ns' style={{}}>
            <div className='mw6'>
              <div className='pt4 f2 fw9 tc pink'>Employment</div>
              <div className='pt4 f4 fw7'>Interested in the art of wood-fired pizza making?</div>
              <div className='pt3'>
          
              Come join our team. No previous experience required. We're looking for conscientious, dependable, & enthusiastic individuals!

              <div className='pt2'>
                Pay ranges from $12-$18 per hour plus tips.
              </div>
              
              {/* All positions begin at the most basic level and advance with experience, capability, and knowledge. 
              Starting pay is a gauaranteed minimum of $12/hr (base pay of $9/hr + tips) and pizzaiolo status (a master of the pizzeria) is a gauranteed minimum pay of $18/hr (base pay of $15/hr + tips). Hours are currently limited to Friday and Saturday, but will increase as we continue opening.
              Prior experience is not necessary, but you must be hard working & diligent: attention to detail is an absolute must. */}

              {/* , with promotions coming  */}
                        {/* <div className='w-100 pt3 pb5 ph4 pink f4 flex flex-column items-center tc'>
                          <div className='pt5 f3 fw7'>Pizzeolia</div>
                          <div>Gauranteed minimum pay of $15/hr. Base pay of $10/hr + tips. </div>
                          <div>Must be hard working, diligent & personable. Prior experience not necessary. </div>
                          <div>Hours are currently Friday and Saturday but will increase as store continues its opening. </div> */}

              <div className='pt2'>Download your application <a href = {application} target = "_blank">here</a>. Please send your application to careers@getsauced.pizza</div>
            </div>
          </div>
          </div>

        {/* <div>
          <div id='hours' className='w-100 pa3 bg-white f3 fw7 sticky' style={{top:headerHeight, zIndex:3}}> <a className='w-100 link pink' href='https://order.getsauced.pizza/' >Now Hiring!</a></div>


          
Interested in the art of wood-fired pizza making? Come join our team. All positions begin at the most basic level and advance with experience, capability, and knowledge. 
Starting pay is a gauaranteed minimum of $12/hr (base pay of $9/hr + tips) and pizzaiolo status (a master of the pizzeria) is a gauranteed minimum pay of $18/hr (base pay of $15/hr + tips). Hours are currently limited to Friday and Saturday, but will increase as we continue opening.
Prior experience is not necessary, but you must be hard working & diligent: attention to detail is an absolute must.

, with promotions coming 
          <div className='w-100 pt3 pb5 ph4 pink f4 flex flex-column items-center tc'>
            <div className='pt5 f3 fw7'>Pizzeolia</div>
            <div>Gauranteed minimum pay of $15/hr. Base pay of $10/hr + tips. </div>
            <div>Must be hard working, diligent & personable. Prior experience not necessary. </div>
            <div>Hours are currently Friday and Saturday but will increase as store continues its opening. </div>

            Please send your resume to careers@getsauced.pizza
            <div className='w-100 mw6'>

              <div className='pt5 f2 fw7'>Hours & Directions </div>
              <div className='pt4'><span className='fw6 white'>Sun-Thurs:</span> Coming Soon </div> 
              <div className='pt2'><span className='fw6 white'>Fri-Sat: </span> Noon to 8pm</div>
              <div className='pt3 f6 fw7'>The days and hours we are open will change as we complete our "super soft opening." Check back for updates!</div>

            </div>
          </div>
          <div className='pb5'dangerouslySetInnerHTML={{__html: '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d11713.325445837312!2d-88.41246788427827!3d42.781344300218386!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xcee7b724fd6cb106!2sSauced!5e0!3m2!1sen!2sus!4v1583791241669!5m2!1sen!2sus" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>' }}/>
        </div> */}


<div id='contact' className='pt5 relative flex justify-center' style={{top: 0}}>

        <div className='mw6 w-100 ph4'>
          <div className='w-100 f2 fw9 pink tc'>Contact</div>
          <Textbox
            className='w-100 mt4 pv3 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.name'
            placeholder='Name'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <Textbox
            className='w-100 mt3 pv3 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.email'
            placeholder='Email'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <Textbox
            className='w-100 mt3 pv4 ph3 ba br3 dark-gray b--moon-gray fw4 '
            id='contact.message'
            placeholder='Message'
            style={{ outline: 'none', borderStyle: 'solid' }}
            type='text'
            defaultValue={''}
          />
          <div className='pt3 w-100 flex justify-center justify-between-ns'>
            <div>
            </div>
            <SubmitButton id={'contactSubmit'} className='flex pt2 justify-between' 
              showSuccess={true}
              defaultChild={ props => (
                <div className='dib ph4 pv3 br2 bg-pink white pointer fw6' onClick={e => Dispatch.nextAction('contact.send')}>Submit</div>
              )}
              loadingChild={ props => (
                <div className='w-100 flex justify-between'>
                  <div></div>
                  <div className='w2'>
                    <img src={loading} />
                  </div>
                </div>
              )}
              successChild={ props => (
                <div className='dib ph4 pv3 br2 bg-pink white pointer fw6'>Success</div>
              )}
              errorChild={ props => (
                <div className='pl3 f5 fw7 red'>Error</div>
              )}
              loadingStream={
                Dispatch.getAction('Request.contact')
                }
              successStream={
                Dispatch.getAction('Response.contact')
                  .pipe(
                    delay(500),
                    filter(e => _.isNil(e.errors)),
                  )
                }
              errorStream={Dispatch.getAction('Response.contact')
              .pipe(
                filter(e => !_.isNil(e.errors)),
              )}
            />
          </div>
        </div>
      </div>



        {/* <div className='w-100 pt1 bw1 bb b--pink' style={{zIndex:4}}></div>
          <div className='w-100 pb1 bw2 bb b--pink' style={{zIndex:4}}></div>
          <div className='w-100 pt1 pb1 mb1 bw3 bb b--pink' style={{zIndex:4}}></div>

          <div className='w-100 mt2 pa3 bg-pink f3 fw7 white sticky' style={{top:headerHeight, zIndex:3}}> <a className='w-100 link white' href='https://getsauced.pizza' >Because we love you!</a></div>

          <div className='w-100 bg-black' style={{zIndex:2}}>
            <div className='w-100 pt1 pb1 mt1 bw3 bb b--pink' style={{zIndex:2}}></div>
            <div className='w-100 pt1 pb1 bw2 bb b--pink' style={{zIndex:2}}></div>
            <div className='w-100 pt1 bw1 bb b--pink' style={{zIndex:2}}></div>
          </div> */}



        <div className='mt5 pv3 ph4 bg-black pink fw6 f7 tc'> Copyright 2020 Sauced, LLC</div>
        </>
        :
        <div></div>
      }
    </div>
  );
}

// const Store = (props) => {
//   return (
//     <div>
//       <MobileCart _primaryColor={primaryColor} _secondaryColor={secondaryColor} {...props} />
//         <div className='dn-ns w-100 h5 bg-black flex justify-center'>
//           <img className='w-100 mw7' style={{objectFit: 'cover'}} src={saucedPizza} />
//         </div>
//       <ItemBrowser _primaryColor={primaryColor} _secondaryColor={secondaryColor} {...props} />
//     </div>
//   )
// }

const modalChildren = {
  item: ItemDetail,
  checkout: Checkout,
  payment: Payment,
  thankyou: ThankYou,
  fullfillment: Fullfillment,
  customerDetails: CustomerDetails,
  orderManagerStateModal: OrderManagerStateModal,
}

const AdminView = (props) => {
  return (
    <div className='pv5 ph2 w-100 flex justify-center'><Admin {...props}/></div>
  )
}

function App(props) {
  const viewState = props.view ? props.view : 'home'
  const views = {
    home: Homepage,
    store: Store,
    admin: AdminView,
    menu: MenuView,
    orderManager: OrderManager,
  }
  const View = views[viewState]
  const viewTop = props.view === 'home' ? 70 : props.view === 'orderManager' ? 0 : 123
  //Sauced background  color backgroundColor: 'rgb(150,0,114,.3)'
  return (
    <div className='w-100 avenir'>
      {viewState !== 'menu' ?
        <>
          <div className='w-100'>
            <Modal _className=' modal-size modal-size-ns avenir' shouldCloseOnOverlayClick={true} _children={modalChildren} _style={{overlay: {backgroundColor: 'rgb(0,0,0,.3)'}}} { ...props } />
          </div>
          {viewState !== 'orderManager' && <Header {...props}/>}
          <div className='relative' style={{top: viewTop}}>
            <View {...props}/>
          </div>
        </>
        :
        <View {...props}/>
      }
    </div>
  )
}

export default App;
