import React from 'react';
import {  merge, interval, timer, combineLatest } from 'rxjs';
import { filter, map, mergeMap, 
  startWith,  take, } from 'rxjs/operators';
import Dispatch from '@Corim/architecture-frontend/build/architecture/Dispatch';
import { BaseComponent } from '@Corim/architecture-frontend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollingIcons from './ScrollingIcons'
import { } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleDown, faShoppingBag, faPizzaSlice } from '@fortawesome/free-solid-svg-icons'
import Menu from './Menu'
import _ from 'lodash'

import oven_white from '../media/sauced_oven_white.png'
import oven_black from '../media/sauced_oven_black.png'
import saucedLogoNeon from '../media/sauced_logo_neon.png'
import saucedLogoWhite from '../media/sauced_logo_white.svg'
import sauced_logo_neon_dim from '../media/sauced_logo_neon_dim.svg'
import order_icon from '../media/order_icon.svg'

interval(100)
.pipe(
  map(e => document.querySelector('#logo1')),
  filter(e => e?.complete === true),
  map(e => document.querySelector('#logo2')),
  filter(e => e?.complete === true),
  map(e => document.querySelector('#logo3')),
  filter(e => e?.complete === true),
  map(e => document.querySelector('#logo4')),
  filter(e => e?.complete === true),
  map(e => document.querySelector('#oven1')),
  filter(e => e?.complete === true),
  map(e => document.querySelector('#oven2')),
  filter(e => e?.complete === true),
  map(e => document.querySelector('#oven3')),
  filter(e => e?.complete === true),
  map(e => e.complete),
  take(1)
)
.subscribe(e => Dispatch.mergeState('ui', { loaded: e }))

const flicker$ = timer(0, 2500)
    .pipe(
      mergeMap(e => {
        const randomTime1 = Math.random() * 1000
        const randomTime2 = Math.random() * 1000
        return merge(
          timer(randomTime1)
            .pipe(
              map(e => true)
            ),
          timer(randomTime1+50)
            .pipe(
              map(e => false)
            ),
          timer(randomTime2)
            .pipe(
              map(e => true)
            ),
          timer(randomTime2+50)
            .pipe(
              map(e => false)
            ),
          )
        }
      ),
      startWith(false)
    )

const Intro = (props) => {
  const bgColor = props.ui && props.ui.color
  const flicker = props && props.flicker
  const logo = flicker ? sauced_logo_neon_dim : saucedLogoNeon
  const primaryColor = ' pink '
  const borderColor = ' b--pink '
  const headerHeight = 70
  const whiteTransparent = bgColor === 'white' ? ' o-100 ' : ' o-0 '
  const darkTransparent = bgColor === 'black' ? ' o-100 ' : ' o-0 '
  const darkTransparentLogo = bgColor === 'black' && flicker === false ? ' o-100 ' : ' o-0 '
  const darkDimTransparentLogo = bgColor === 'black' && flicker === true ? ' o-100 ' : ' o-0 '
  const overflow = bgColor === 'white' ? ' overflow-hidden ' : ' overflow-auto '
  const innerHeight = props.ui && props.ui.inner && props.ui.inner.height
  const maxWidth = innerHeight / 1.8 > 550 ? 550 : innerHeight / 1.8
  const loadedOpacity = props.ui && props.ui.loaded ? ' o-100 ' : ' o-0 '
  return (
    <div className={'w-100 ' + loadedOpacity} style={{height: innerHeight*1.5}}>
    <ScrollingIcons {...props}/>
    <div className='w-100 flex items-center flex-column justify-center sticky' style={{top: headerHeight, minHeight:innerHeight - (headerHeight*2)}}>
      <div className='relative pt2 pt1-ns w-90 mw6 flex flex-column items-center' style={{maxWidth}}>
        <div className='relative w-90'>
          <img id='logo1' className='w-100 babypulse o-0' src={saucedLogoNeon} />
          <img id='logo2' className={'absolute w-100 fader babypulse' + darkDimTransparentLogo } src={sauced_logo_neon_dim} style={{left: 0, top:0}}/>
          <img id='logo3' className={'absolute w-100 fader babypulse' + darkTransparentLogo } src={saucedLogoNeon} style={{left: 0, top:0}}/>
          <img id='logo4' className={'absolute w-100 fader babypulse' + whiteTransparent } src={saucedLogoWhite} style={{left: 0, top:0}} />
        </div>
        <div className='relative w-100' style={{top: -10}}>
          <img id='oven1' className={'w-80 o-0' } src={oven_black} />
          <img id='oven2' className={'absolute fader w-80 ' + darkTransparent } src={oven_black} style={{left: "10%", top:-20}} />
          <img id='oven3' className={'absolute fader w-80 ' + whiteTransparent } src={oven_white} style={{left: "10%", top:-20}} />
        </div>
      </div>
      <div className='relative pink' onClick={e => Dispatch.nextState('ui.color', 'black')} style={{top:-40}}>
        {bgColor === 'white' ?
          <div className='pa3 f4 babypulse bg-pink white br3'>Step Inside</div>
          :
          <div className='relative f2 pulse'><FontAwesomeIcon icon={faAngleDown} /> </div>
        }
      </div>
    </div>
    </div>
  )
}

const Store$ = combineLatest(Dispatch.getState(), flicker$)
  .pipe(
    map(([store, flicker]) => ({
      ...store,
      flicker,
    }))
  )

export default (props) => <BaseComponent Component={Intro} store={Store$} {...props}/>