import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import './index.css';
import './clock.css'
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Dispatch, BaseComponent, Request } from '@Corim/architecture-frontend';
import 'tachyons';
import './engines/index'
import { StoreEngine } from '@Corim/squareshop'
import Apollo from './apollo'

Request(Dispatch, Apollo)
StoreEngine()

ReactGA.initialize('UA-157389183-1');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactDOM.render(<BaseComponent Component={App} />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();