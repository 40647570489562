import React from 'react';
import { Dispatch, Textbox, Select, SubmitButton } from '@Corim/architecture-frontend'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faParachuteBox, faArrowAltCircleLeft, faBars, faPizzaSlice, faFish, faIceCream, faPlus} from '@fortawesome/free-solid-svg-icons'
import _ from 'lodash'

// chromium-browser --window-position=0,0    --kiosk --user-data-dir="/home/pi/Documents/Profiles/0" https://theslideshow.net/#simple/dog
//$ chromium-browser --window-position=1920,0 --kiosk --user-data-dir="/home/pi/Documents/Profiles/1" https://theslideshow.net/#simple/llama

// $chromePath = 'C:\Program Files (x86)\Google\Chrome\Application\chrome.exe'
//$chromeArguments = '--new-window --incognito'
//# &taskkill /im chrome* /F 
//Chrome-Kiosk 'http://google.com' -MonitorNum 1 
//Chrome-Kiosk 'http://http://www.bbc.com/' -MonitorNum 2 
//

// start C:\Users\terminal\AppData\Local\Google\Chrome\Application\chrome.exe --app="http://www.domain1.com" --window-position=0,0 --kiosk --user-data-dir=c:/monitor1
// start C:\Users\terminal\AppData\Local\Google\Chrome\Application\chrome.exe --app="http://www.domain2.com" --window-position=1680,0 --kiosk --user-data-dir=c:/monitor2

const Decoration = (props) => {
  return (
      <div className='w-100 pv2 bg-pink flex items-center justify-between white f4'>
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
          <FontAwesomeIcon icon={faPizzaSlice} />
          <FontAwesomeIcon icon={faIceCream} />
    </div>
  )
}

const Category = (props) => {
  const categoryName = props._categoryName
  const categoryDescription = props._categoryDescription
  const categorySubDescription = props._categorySubDescription
  return (
    <div className='relative pt3 pb3 ph3 pink tl flex flex-column ' style={{width:412}}>
      <div className='pink f2 fw9 pt2'>{categoryName}</div>

      <div className='white f4 fw7 pt2' style={{whiteSpace: 'pre-line'}}>{categoryDescription}</div>
      <div className='white f6 fw5 pt1' style={{whiteSpace: 'pre-line'}}>{categorySubDescription}</div>
      {/* <div className='pt1 white f3' style={{}}>Neopolitan Style Wood Fired Pizzas</div> */}
      <div className='w-80 mt2 bg-gray' style={{height:1}}></div>
    </div>
  )
}

const Item = (props) => {
  const item = props._item
  const itemName = item?.item_data?.name
  const itemDescription = item?.item_data?.description
  const variations = item?.item_data?.variations ?? []
  const amount = item.sold_out ? 'Sold Out' : variations.length > 1 ? 'Variable Cost' : variations[0].item_variation_data.price_money && 
  variations[0].item_variation_data.price_money.amount ? 
    '$' + (variations[0].item_variation_data.price_money.amount/100).toFixed(2)
    :
    'No Price'
  return (
    <div className=' pt3 ph3 tl flex flex-column' style={{width:412}}>
      <div className='w-100 flex justify-between'>
        <div className='white f4 fw7'>{itemName}</div>
        <div className='white pl3 f5 fw5'>{amount}</div>
      </div>
      <div className='w-100 pt1 pink f5 fw7'>{itemDescription}</div>
    </div>
  )
}

export default (props) => {
  const categories = props?.__?.Shop?.categories ?? []
  const items = props?.__?.Shop?.items ?? []
  const height = props?.ui?.inner?.height ?? 0
  return(
    <div className='w-100 vh-100 bg-black overflow-y-hidden' >
      <Decoration />
      <div className='w-100 flex justify-between'>
        <div className='pt3 mt3 ph4 flex flex-column flex-wrap' style={{height: height - 135, flexShrink:0}}>
          {categories.map(category => {
            const categoryName = category?.category_data?.name
            const categoryDescription = category?.vendor_data?.description
            const categorySubDescription = category?.vendor_data?.subDescription
            const categoryItems = _.filter(items, item => item?.item_data?.category_id === category?.id)
            return (
              <>
                <Category _categoryName={categoryName} _categoryDescription={categoryDescription} _categorySubDescription={categorySubDescription} {...props} />
                {categoryItems.map(item => {
                  //const itemName = item?.item_data?.name
                  //const itemDescription = item?.item_data?.description
                  return (
                    <Item _item={item} {...props} />
                  )
                })}
              </>
              )
          })
          }
        </div>
        <div></div>
      </div>
      <div className='absolute w-100' style={{bottom:0}}>
          <Decoration />
        </div>
    </div>
  )
}