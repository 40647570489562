import { Dispatch } from '@Corim/architecture-frontend'
import { startWith, map, filter, delay, switchMap, tap, takeUntil, skip, share, distinctUntilChanged, take, publishReplay, refCount, withLatestFrom } from 'rxjs/operators'
import { timer, of, merge, fromEvent, interval, Observable } from "rxjs";
import _ from 'lodash'

Dispatch.getAction('view.store')
  .subscribe(e => {
    Dispatch.nextState('view', 'store')
    window.scrollTo(0,0)
  })

  Dispatch.getAction('view.admin')
  .subscribe(e => {
    Dispatch.nextState('view', 'admin')
    window.scrollTo(0,0)
  })


  Dispatch.getAction('view.menu')
  .subscribe(e => {
    Dispatch.nextState('view', 'menu')
    window.scrollTo(0,0)
  })

Dispatch.getAction('view.orderManager')
.subscribe(e => {
  Dispatch.nextState('view', 'orderManager')
  window.scrollTo(0,0)
})

Dispatch.getAction('view.home')
  .subscribe(scrollTo => {
    Dispatch.nextState('view', 'home')
    if (scrollTo) {
      of(1)
        .pipe(
          delay(400),
          take(1),
        )
        .subscribe(e => document.getElementById(scrollTo.scrollTo).scrollIntoView())
    }
  })