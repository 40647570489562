import React from 'react';
//import logoWithChamber from './media/logo with chamber.svg'
import { Dispatch } from '@Corim/architecture-frontend'

const StoreHeader = (props) => {
  return (
    <div>
       
    </div>
  )
}

const PaymentSuccess = (props) => {
  const payment = props.modal && props.modal.payment ? props.modal.payment : {}
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const primaryColor = shop.primaryColor
  const secondaryColor = shop.secondaryColor
  return (
      <div className='w-100 h-100 tc'>
        <div className='w-100 f2 fw9 tc'>Thank you for shopping at Sauced! </div>
        <div className='w-100 pt4 f5 fw5 tc'> Your order will be ready shortly. An email receipt has been sent to you along with a confirmation of your pickup time. </div>
        <div className='w-100 pt4 f3 fw8 tc'>Enjoy!</div>
        <div className='h-100 flex flex-column justify-between items-center'>
          <div></div>
          <div>
            <a className='link' target="_blank" href={'https://squareup.com/receipt/preview/' + shop?.payment.id} >
              <div className={'mt5 ph4 pv3 br2 tc f4 fw7 bg-' + secondaryColor + ' ' + primaryColor}> View Receipt </div>
            </a>
            <div className='pt1 f6 fw7 tc'>(a receipt will also be emailed to you)</div>
          </div>
        </div>
        <div className='h4'></div>
        {/* <div className='pt4 fw8'>Receipt:</div>
        <div className='pt3'>Confirmation #{ payment.receipt_number }</div>
        <div className='pt1'>Ready At: {readyTime} </div>
        <div className='pt3'>For a detailed and printable receipt click h */}
      </div>
  )
}

const checkoutTerms = <div className='pt4 fw4 fw3-ns f6 dark-gray'></div>

// Additionally, here are our <a href='/?view=terms' target="_blank">terms & conditions</a>. </div>

const fullmentOptionExample = [
  {
    id: 1,
    method: 'Pickup',
    slug: 'PICKUP',
    schedules: [
      // #1 is normall "all at once"
      {id: 1, type: 'ASAP'},
      //{id: 2, type: 'ASAP'},
      {id: 3, type: 'Scheduled'},
    ]
  },
  // {
  //   id: 2,
  //   method: 'Delivery',
  //   slug: 'DELIVERY',
  //   schedules:[
  //     {id: 1, type: 'All At Once'},
  //     {id: 3, type: 'Scheduled'},
  //   ]
  // },
  // {
  //   id: 3,
  //   method: 'Sit Down',
  //   schedules: [
  //     {id: 1, type: 'ASAP'},
  //     {id: 2, type: 'All At Once'},
  //     {id: 3, type: 'Scheduled'},
  //   ]
  // }
]

const settings = {
  //categorySearch: true,
  categorySearch: [{name: 'All', tags: [] }, { name: 'Pizza', tags: ['pizza', 'pizza rossa', 'pizza bianca'] }, { name: 'Beverages', tags: ['beverage', 'soda', 'milk', 'water'] }, { name: 'Dessert', tags: ['gelato', 'dessert'] }],
  StoreHeader,
  PaymentSuccess,
  pollInterval: 50000,
  checkoutTerms,
  fullfillmentMethods: fullmentOptionExample,
  defaultTip: 10,
  displayRemainingTime: false,
  displayEarliestAvailability: false,
}
Dispatch.mergeState('__.Shop', settings)