import React from 'react';
import {  merge, interval, timer, combineLatest } from 'rxjs';
import { filter, map, mergeMap, 
  startWith,  take, } from 'rxjs/operators';
import { Dispatch } from '@Corim/architecture-frontend';
import { BaseComponent } from '@Corim/architecture-frontend';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ScrollingIcons from './ScrollingIcons'
import { } from '@fortawesome/pro-duotone-svg-icons'
import { faAngleDown, faShoppingBag, faPizzaSlice } from '@fortawesome/free-solid-svg-icons'
import Menu from './Menu'
import { ShoppingIcon } from '@Corim/squareshop'

import order_icon from '../media/order_icon.svg'

export default (props) => {
  const bgColor = props.ui && props.ui.color
  const borderColor = ' b--pink '
  const shop = props.__ && props.__.Shop ? props.__.Shop : {}
  const details = shop.order && shop.order.details
  const count = shop?.order?.line_items?.reduce((acc, next) => { return parseInt(next.quantity) + acc }, 0)
  const child = (props) => {
    return (
      <div className='flex justify-center flex-column'>
        <img className='' src={order_icon} style={{height: 42, paddingTop: 5}}/>
      </div> 
    )
  }
  const shopChild = (props) => {
    return (
      <div className='f2 pink flex items-center pointer'>
            <div className='w1 relative flex justify-center' style={{left: 24, top: 7}}>
              {count ? <div className='w-100 f5 fw7 tc white'>
                {count}
              </div>
              :
              null
            }
            </div>
            <FontAwesomeIcon icon={faShoppingBag} />
        </div>
 
    )
  }
  return (
    <div className={ 'fixed w-100 bb bw1 flex flex-column items-center justify-center bg-fader bg-' + bgColor + borderColor} style={{zIndex:100}}>
      <div className='ph5-ns w-100 flex justify-between items-center bb b--gray'>
        <Menu />
        <div className='pr3'>
          <ShoppingIcon _child={child} _shopChild={shopChild} {...props} />
        </div>
      </div>
      {props.view === 'store' && <div style={{height: 45}} className='w-100 bg-dark-gray tc white f5 fw7 bb b--gray flex items-center justify-center'>
        <div className='ph2'>{shop.headerMessage}</div>
      </div>}
    </div>
  )
}